import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import { page } from "components/page";
import gql from "graphql-tag";
import GraphqlFragment from "lib/GraphqlFragment";
import DataList from "components/list/DataList";
import SeminarDisplayComp from "components/resource/SeminarDisplayComp";

import navigate from "lib/navigate";
import qs from "query-string";

const getSeminarList = gql`
  query getSeminarList($searchForm: SearchForm){
    getSeminarList(Input: $searchForm){
      ${GraphqlFragment.defaultLayoutReturn}
    }
  }
  ${GraphqlFragment.defaultLayoutFragment} 
`;

@withI18next(["common"])
@page
class SeminarList extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  componentWillReceiveProps(props) {
    if (this.props !== props) {
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    if (JSON.stringify(state) !== JSON.stringify(this.state)) {
      this.setState({
        ...state,
      });
    }
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore } = props;
    let params = { ...search };
    let { pageNo, limit, resourceType } = params;

    pageNo = pageNo || 1;
    //limit = limit || 10;  //前端限制不換頁
    limit = 99999;

    let searchForm = {
      pageNo,
      limit,
      resourceType,
    };

    Object.keys(searchForm).map((key) => {
      if (
        searchForm[key] === null ||
        (typeof searchForm[key] === "string" && searchForm[key].trim() === "")
      ) {
        delete searchForm[key];
      }
    });

    let state = {
      searchForm: searchForm,
      auth: readerStore.auth,
      resourceType: resourceType,
    };

    return state;
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="col-6-6 main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accesskey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("hyint.common.seminar")}</h2>
              <a
                href="#!"
                className="back"
                onClick={(e) => {
                  e.preventDefault();
                  //history.back();
                  navigate(-1);
                }}>
                {this.props.t("hyint.common.table.back")}
              </a>

              <DataList
                query={getSeminarList}
                variables={{
                  searchForm: this.state.searchForm,
                }}
                fetchPolicy={"network-only"}
                displayComp={SeminarDisplayComp}
                pagination={"NormalPagination"}
                showPagination={false}
              />
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default SeminarList;
