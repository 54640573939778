import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import navigate from "lib/navigate";
//import moment from 'moment';

import {
  arrangeData,
  encodeJsonString,
} from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";
import { autobind } from "react-decoration";

class NewsDisplayComp extends View {
  constructor(props) {
    super(props);
  }

  @autobind
  apply(e) {
    e.preventDefault();
    //console.log(e.target.dataset.applyurl);
    if(e.target.dataset.applyurl!=""){
      window.open(e.target.dataset.applyurl, '_blank')
    }else{
      var url = "/seminarDetail?id="+ e.target.dataset.sn;
      //console.log(url);
      //console.log(e.target.dataset.sn);
      navigate(url);
    }
  }

  render() {
    let {
      displayConfig,
      data,
      resourceData,
      functionConfigList,
      functionRightConfigList,
      buttonEvent,
      batchConfig,
      rowStyle,
      serialNo,
      auth,
      handleClick,
      displayCheckBox,
      i18n,
      t,
      info,
      refetch,
    } = this.props;

    const arrangedData = arrangeData(data);

    const { sn, seminar_name,activity_time,location,speakers,target,register_sdate,register_edate,startdate,expdate,apply_url,applyCnt,max_attendence } = arrangedData;

    let { language } = i18n;

    var now = new Date();
    var today = now.getFullYear()+""+(now.getMonth()<9?"0":"")+(now.getMonth()+1)+""+(now.getDate()<10?"0":"")+now.getDate();

    //console.log("today="+today)
    //console.log("startdate="+arrangedData.register_sdate.substring(0,8))
    //console.log("enddate="+arrangedData.register_edate.substring(0,8))

    return (
      <>
        <div className="col">
          <div className="featured_data">
            <h3>
              <a
                href="#"
                tabIndex="0"
                data-sn={sn}
                data-applyurl={apply_url}
                onClick={this.apply.bind(this)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.apply(e);
                  }
                }}>
                {seminar_name}
              </a>
            </h3>
            <div className="content">
              <ul>
                <li className="m_show">
                  <span>
                    {t("hyint.esource.seminar.activity_time")}
                    {t("hyint.esource.seminar.colon")}
                  </span>
                  {activity_time}
                </li>
                <li>
                  <span>
                    {t("hyint.esource.seminar.location")}
                    {t("hyint.esource.seminar.colon")}
                  </span>
                  {location}
                </li>
                <li>
                  <span>
                    {t("hyint.esource.seminar.speakers")}
                    {t("hyint.esource.seminar.colon")}
                  </span>
                  {speakers}
                </li>
                <li>
                  <span>
                    {t("hyint.esource.seminar.target")}
                    {t("hyint.esource.seminar.colon")}
                  </span>
                  {target}
                </li>
                <li className="m_show">
                  <span>報名期限{t("hyint.esource.seminar.colon")}</span>
                  {register_sdate.substring(0, 8)} ~{" "}
                  {typeof register_edate != "undefined"
                    ? register_edate.substring(0, 8)
                    : t("hyint.esource.seminar.Infinite")}
                </li>
              </ul>
              <div className="quota">
                <div className="status">
                  {parseInt(arrangedData.register_edate.substring(0, 8)) <
                  parseInt(today)
                    ? "已截止報名"
                    : arrangedData.max_attendence !== "0" &&
                      parseInt(arrangedData.max_attendence) <= parseInt(arrangedData.applyCnt)
                    ? "人數已滿"
                    : parseInt(arrangedData.register_sdate.substring(0, 8)) >
                      parseInt(today)
                    ? "尚未開始"
                    : "報名中"}
                </div>
                <div className="amount">
                  {max_attendence == "0" ? (
                    t("hyint.esource.seminar.Infinite")
                  ) : (
                    <>
                      <span>{applyCnt}</span>
                      <span className="total">{max_attendence}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            {parseInt(arrangedData.register_edate.substring(0, 8)) <
            parseInt(today) ? (
              <a href="#" className="apply">
                已截止報名
              </a>
            ) : arrangedData.max_attendence !== "0" &&
            parseInt(arrangedData.max_attendence) <= parseInt(arrangedData.applyCnt) ? (
              <a href="#" className="apply">
                人數已滿
              </a>
            ) : parseInt(arrangedData.register_sdate.substring(0, 8)) >
              parseInt(today) ? (
              <a href="#" className="apply">
                尚未開始
              </a>
            ) : (
              <a
                href="#"
                tabIndex="0"
                className="apply"
                data-sn={sn}
                data-applyurl={apply_url}
                onClick={this.apply.bind(this)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.apply(e);
                  }
                }}>
                我要報名
              </a>
            )}
          </div>
        </div>
      </>
    );
  }
}

NewsDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

NewsDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default NewsDisplayComp;
